import * as React from "react";
import { Helmet } from "react-helmet";
import Logo from "../images/DTC2_Logo.png";

const isBrowser = typeof window !== "undefined";

const auth = () => {
  React.useEffect(() => {
    if (isBrowser) {
      if (window.opener !== null) {
        window.opener.PostAuthData(document.location.hash);
      } else {
        if (process.env.NODE_ENV === "production") {
          window.location.href = "https://dastreamcharades.net";
        } else {
          window.location.href = "localhost:8000";
        }
      }
      history.pushState("", document.title, window.location.pathname);
    }
  }, []);

  return (
    <>
      <main className="relative">
        <Helmet>
          <title>Auth - Da Stream Charades</title>
          <link href={Logo} rel="shortcut icon" type="image/x-icon" />
        </Helmet>
        <span className="text-gray-400">Authed</span>
      </main>
    </>
  );
};

export default auth;
